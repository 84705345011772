// Note: To see the full tailwind config, go to
// tailwindcss/stubs/defaultConfig.stub.js in node_modules
const plugin = require("tailwindcss/plugin");

module.exports = {
  theme: {
    textColor: (theme) => theme("colors"),
    backgroundColor: (theme) => ({
      ...theme("colors")
    }),
    borderColor: (theme) => ({
      ...theme("colors"),
      DEFAULT: theme("colors.gray.90", "currentColor")
    }),

    colors: {
      transparent: "transparent",
      current: "currentColor",
      primary: "#0000FF",
      secondary: "#CCCCFF",
      white: "#FFFFFF",
      black: "#000000",
      primaryblue: {
        20: "#000066",
        30: "#000099",
        40: "#0000CC",
        50: "#0000FF",
        60: "#3333FF",
        70: "#6666FF",
        80: "#9999FF",
        90: "#CCCCFF",
        95: "#E5E5FF"
      },
      blue: {
        20: "#003366",
        30: "#004C99",
        40: "#0066CC",
        50: "#007FFF",
        60: "#3399FF",
        70: "#66B2FF",
        80: "#99CCFF",
        90: "#CCE5FF",
        95: "#E5F2FF"
      },
      lightblue: {
        20: "#004C66",
        30: "#007399",
        40: "#0099CC",
        50: "#00BFFF",
        60: "#33CCFF",
        70: "#66D9FF",
        80: "#99E5FF",
        90: "#CCF2FF",
        95: "#E5F9FF"
      },
      red: {
        20: "#660000",
        30: "#990000",
        40: "#CC0000",
        46: "#EC0000",
        60: "#FF3333",
        70: "#FF6666",
        80: "#FF9999",
        90: "#FFCCCC",
        95: "#FFE5E5"
      },
      orange: {
        20: "#663300",
        30: "#994D00",
        40: "#CC6600",
        50: "#FF8000",
        60: "#FF9933",
        70: "#FFB366",
        80: "#FFCC99",
        90: "#FFE6CC",
        95: "#FFF2E5"
      },
      gray: {
        20: "#2A303C",
        30: "#3F485A",
        40: "#535F78",
        50: "#697896",
        60: "#8793AB",
        70: "#A5AEC0",
        80: "#C3C9D5",
        90: "#E1E4EA",
        95: "#F0F1F5",
        97: "#F6F7F9"
      },
      yellow: {
        20: "#665200",
        30: "#997A00",
        40: "#CCA300",
        50: "#FFCC00",
        60: "#FFD633",
        70: "#FFE066",
        80: "#FFEB99",
        90: "#FFF5CC",
        95: "#FFFAE5"
      },
      lime: {
        20: "#5C5C0A",
        30: "#8A8A0F",
        40: "#B8B814",
        50: "#E6E619",
        60: "#EBEB47",
        70: "#F0F075",
        80: "#F5F5A3",
        90: "#FAFAD1",
        95: "#FDFDE8"
      },
      green: {
        20: "#0A5C1F",
        29: "#0F852D",
        40: "#14B83E",
        50: "#19E64D",
        60: "#47EB71",
        70: "#75F094",
        80: "#A3F5B8",
        90: "#D1FADB",
        95: "#E8FDED"
      },
      mint: {
        20: "#0A5C47",
        30: "#0F8A6B",
        40: "#14B88E",
        50: "#19E6B2",
        60: "#47EBC1",
        70: "#75F0D1",
        80: "#A3F5E0",
        90: "#D1FAF0",
        95: "#E8FDF7"
      },
      teal: {
        20: "#0A5C5C",
        30: "#0F8A8A",
        40: "#14B8B8",
        50: "#19E6E6",
        60: "#47EBEB",
        70: "#75F0F0",
        80: "#A3F5F5",
        90: "#D1FAFA",
        95: "#E8FDFD"
      },
      violet: {
        20: "#330066",
        30: "#4C0099",
        40: "#6600CC",
        50: "#7F00FF",
        60: "#9933FF",
        70: "#B266FF",
        80: "#CC99FF",
        90: "#E5CCFF",
        95: "#F2E5FF"
      },
      purple: {
        20: "#4C0066",
        30: "#730099",
        40: "#9900CC",
        50: "#BF00FF",
        60: "#CC33FF",
        70: "#D966FF",
        80: "#E599FF",
        90: "#F2CCFF",
        95: "#F9E5FF"
      },
      fuchsia: {
        20: "#66004C",
        30: "#990073",
        40: "#CC0099",
        50: "#FF00BF",
        60: "#FF33CC",
        70: "#FF66D9",
        80: "#FF99E5",
        90: "#FFCCF2",
        95: "#FFE5F9"
      },
      attention: "#FFCC00",
      negative: "#EC0000",
      positive: "#14B83E"
    },
    fontFamily: {
      main: [
        "Apercu",
        "Avenir",
        "-apple-system",
        "BlinkMacSystemFont",
        "Helvetica Neue",
        "Helvetica",
        "Calibri",
        "Roboto",
        "Arial",
        "sans-serif"
      ],

      mono: [
        "Apercu Mono",
        "SFMono-Regular",
        "Menlo",
        "Monaco",
        "Consolas",
        "Liberation Mono",
        "Courier New",
        "monospace"
      ]
    },
    fontSize: {
      "5xl": ["4rem", { lineHeight: "5rem" }],
      "4xl": ["3.5rem", { lineHeight: "4.5rem" }],
      "3xl": ["3rem", { lineHeight: "4rem" }],
      "2xl": ["2.5rem", { lineHeight: "3.5rem" }],
      xl: ["2.25rem", { lineHeight: "3rem" }],
      lg: ["2rem", { lineHeight: "2.5rem" }],
      md: ["1.75rem", { lineHeight: "2.25rem" }],
      sm: ["1.5rem", { lineHeight: "2rem" }],
      xs: ["1.25rem", { lineHeight: "1.75rem" }],
      "2xs": ["1rem", { lineHeight: "1.5rem" }],
      "3xs": [".875rem", { lineHeight: "1.25rem" }],
      "4xs": [".75rem", { lineHeight: "1rem" }],
      "5xs": [".625rem", { lineHeight: ".875rem" }]
    },
    boxShadow: {
      DEFAULT: "0 0 12px rgba(0, 0, 0, 0.08)"
    },
    extend: {
      spacing: {
        "4xs": "2px",
        "3xs": "4px",
        "2xs": "8px",
        xs: "12px",
        sm: "16px",
        md: "24px",
        lg: "32px",
        xl: "48px",
        "2xl": "64px",
        "3xl": "72px",
        "4xl": "128px"
      },
      borderRadius: {
        none: "0px",
        sm: "4px",
        md: "6px",
        lg: "8px",
        xl: "16px"
      }
    },

    fontWeight: {
      normal: 400,
      medium: 500,
      bold: 600
    },
    ringColor: (theme) => ({
      ...theme("colors"),
      DEFAULT: theme("colors.primary")
    }),
    fill: {
      current: "currentColor"
    }
  },
  plugins: [
    plugin(function ({ addUtilities, theme }) {
      const newLinkStyles = {
        ".link-primary": {
          color: theme("colors.primary"),
          marginBottom: theme("spacing.4xs"),
          cursor: "pointer"
        },
        ".link-primary:hover": {
          borderStyle: "solid",
          borderColor: theme("colors.primary"),
          borderBottomWidth: "1px"
        },
        ".link-underline": {
          color: theme("colors.gray.40"),
          marginBottom: theme("spacing.4xs"),
          borderStyle: "solid",
          borderColor: theme("colors.gray.40"),
          borderBottomWidth: "1px",
          cursor: "pointer"
        },
        ".link-underline:hover": {
          color: theme("colors.black"),
          borderStyle: "solid",
          borderColor: theme("colors.black"),
          borderBottomWidth: "1px"
        }
      };
      const newTextStyles = {
        ".text-display": {
          fontFamily: theme("fontFamily.main"),
          fontWeight: theme("fontWeight.bold")
        },
        ".text-heading": {
          fontFamily: theme("fontFamily.main"),
          fontWeight: theme("fontWeight.bold")
        },
        ".text-subheading": {
          fontFamily: theme("fontFamily.main"),
          fontWeight: theme("fontWeight.medium")
        },
        ".text-body": {
          fontFamily: theme("fontFamily.main"),
          fontWeight: theme("fontWeight.normal")
        },
        ".text-label": {
          fontFamily: theme("fontFamily.mono"),
          fontWeight: theme("fontWeight.normal"),
          textTransform: "uppercase"
        }
      };
      addUtilities([newTextStyles, newLinkStyles]);
    })
  ]
};
