import React from 'react';
import cn from 'classnames';
import { SemanticSIZES } from 'semantic-ui-react';
// @ts-ignore
import { getElementType } from 'semantic-ui-react/dist/commonjs/lib';

import SkeletonLine from './SkeletonLine';

export interface SkeletonParagraphProps extends StrictSkeletonParagraphProps {
  [key: string]: any;
}
export interface StrictSkeletonParagraphProps {
  /** An element type to render as (string or function). */
  as?: any;

  /** Skeleton line size. */
  size?: SemanticSIZES;

  /** Number of skeleton lines in the paragraph */
  rows?: number;

  /** Is animated. */
  animated?: boolean;

  /** Additional classes. */
  className?: string;
}

export const SkeletonParagraph: React.FC<SkeletonParagraphProps> = (props) => {
  const { size, animated, rows = 1, className, ...rest }: SkeletonParagraphProps = props;
  const ElementType = getElementType(SkeletonParagraph, props);

  return (
    <ElementType className={cn('skeleton-paragraph', className)} {...rest}>
      {[...Array(rows)].map((val, index) => (
        <SkeletonLine key={index.toString()} size={size} animated={animated} />
      ))}
    </ElementType>
  );
};

SkeletonParagraph.displayName = 'SkeletonParagraph';

export default SkeletonParagraph;
