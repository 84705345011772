import React from 'react';
import cn from 'classnames';
import { SemanticSIZES } from 'semantic-ui-react';
// @ts-ignore
import { getElementType } from 'semantic-ui-react/dist/commonjs/lib';

export type TextLinesSizes = 'text-large' | 'text-normal' | 'text-small' | 'text-x-small' | 'text-xx-small';

export interface SkeletonLineProps extends StrictSkeletonLineProps {
  [key: string]: any;
}

export interface StrictSkeletonLineProps {
  /** An element type to render as (string or function). */
  as?: any;

  /** Skeleton line size. */
  size?: SemanticSIZES;

  /** Additional classes. */
  className?: string;

  /** Content. */
  children?: React.ReactNode;

  /** Is animated. */
  animated?: boolean;
}

export const SkeletonLine: React.FC<SkeletonLineProps> = (props) => {
  const { size, animated = true, className, ...rest }: SkeletonLineProps = props;
  const classes = cn(className, 'skeleton-line', 'ui', 'skeleton', {
    [size as string]: !!size,
    animation: animated,
  });

  const ElementType = getElementType(SkeletonLine, props);

  return <ElementType className={classes} {...rest} />;
};

SkeletonLine.displayName = 'SkeletonLine';

export default SkeletonLine;
