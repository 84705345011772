import React, { cloneElement, useEffect, useState } from 'react';

import { Loader } from 'semantic-ui-react';
import {
  currentEmployeeHasRoles,
  currentEmployeeHasRolesByAnyUserLocation,
  currentEmployeeHasRolesByAnyAccountLocation,
  currentEmployeeHasPermissions,
  currentEmployeeHasPermissionsByAnyUserLocation,
  currentEmployeeHasPermissionsByAnyAccountLocation,
} from 'api/operatorService';
import { OperatorServicePermissionsMap, OperatorServiceRolesMap } from 'api/types';

export interface ErrorComponentProps {
  error: Error;
}

export interface CustomErrorAndLoadingProps {
  errorComponent?: React.ComponentType<ErrorComponentProps>;
  loadingComponent?: React.ComponentType;
}

export interface WithRequestedAuthzProps extends CustomErrorAndLoadingProps {
  requestedRoles: OperatorServiceRolesMap;
  requestedPermissions: OperatorServicePermissionsMap;
}
export interface WithRequestedAuthzByUserProps extends CustomErrorAndLoadingProps {
  requestedRolesByUser: OperatorServiceRolesMap;
  requestedPermissionsByUser: OperatorServicePermissionsMap;
}
export interface WithRequestedAuthzByAccountProps extends CustomErrorAndLoadingProps {
  requestedRolesByAccount: OperatorServiceRolesMap;
  requestedPermissionsByAccount: OperatorServicePermissionsMap;
}

interface WithRequestedAuthzHOCProps extends CustomErrorAndLoadingProps {
  roles?: string[];
  permissions?: string[];
}
// The roles/permissions props are "namespaced" to prevent collisions with
// WithRequestedAuthzHOCProps (in case they are used together).
interface WithRequestedAuthzByUserHOCProps extends CustomErrorAndLoadingProps {
  userUuid: string;
  byUserRoles?: string[];
  byUserPermissions?: string[];
}
interface WithRequestedAuthzByAccountHOCProps extends CustomErrorAndLoadingProps {
  accountUuid: string;
  byAccountRoles?: string[];
  byAccountPermissions?: string[];
}

interface WithRequestedAuthzComponentProps extends WithRequestedAuthzHOCProps {
  children: React.ReactElement;
}
interface WithRequestedAuthzByUserComponentProps extends WithRequestedAuthzByUserHOCProps {
  children: React.ReactElement;
}
interface WithRequestedAuthzByAccountComponentProps extends WithRequestedAuthzByAccountHOCProps {
  children: React.ReactElement;
}

export const DefaultErrorComponent: React.FC<ErrorComponentProps> = ({ error }) => <div>{error.message}</div>;
export const DefaultLoadingComponent: React.FC = () => <Loader active />;

function useAsync<T>(arg: string[], fn: () => Promise<T>): [boolean, Error | undefined, T] {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState({} as T);

  useEffect(() => {
    let mounted = true;
    if (arg.length === 0) {
      setLoading(false);
      return;
    }

    const asyncFn = async (): Promise<void> => {
      try {
        const result = await fn();
        if (mounted) {
          setData(result);
          setLoading(false);
        }
      } catch (error) {
        if (mounted) {
          setError(error);
          setLoading(false);
        }
      }
    };

    asyncFn();

    // we use a cleanup function here to ensure we dont call any more hooks asynchronously after we have unmounted
    return (): void => {
      mounted = false;
    };
  }, [JSON.stringify(arg)]);

  return [loading, error, data];
}

export const useRequestedRoles = (roles: string[]): [boolean, Error | undefined, OperatorServiceRolesMap] =>
  useAsync(roles, () => currentEmployeeHasRoles(roles));
export const useRequestedRolesByAnyUserLocation = (
  userUuid: string,
  roles: string[],
): [boolean, Error | undefined, OperatorServiceRolesMap] =>
  useAsync(roles, () => currentEmployeeHasRolesByAnyUserLocation(userUuid, roles));
export const useRequestedRolesByAnyAccountLocation = (
  accountUuid: string,
  roles: string[],
): [boolean, Error | undefined, OperatorServiceRolesMap] =>
  useAsync(roles, () => currentEmployeeHasRolesByAnyAccountLocation(accountUuid, roles));
export const useRequestedPermissions = (
  permissions: string[],
): [boolean, Error | undefined, OperatorServicePermissionsMap] =>
  useAsync(permissions, () => currentEmployeeHasPermissions(permissions));
export const useRequestedPermissionsByAnyUserLocation = (
  userUuid: string,
  roles: string[],
): [boolean, Error | undefined, OperatorServicePermissionsMap] =>
  useAsync(roles, () => currentEmployeeHasPermissionsByAnyUserLocation(userUuid, roles));
export const useRequestedPermissionsByAnyAccountLocation = (
  accountUuid: string,
  roles: string[],
): [boolean, Error | undefined, OperatorServicePermissionsMap] =>
  useAsync(roles, () => currentEmployeeHasPermissionsByAnyAccountLocation(accountUuid, roles));

export const WithRequestedAuthz = ({ children, ...props }: WithRequestedAuthzComponentProps): JSX.Element => {
  const roles = props.roles || [];
  const permissions = props.permissions || [];
  const LoadingComponent = props.loadingComponent || DefaultLoadingComponent;
  const ErrorComponent = props.errorComponent || DefaultErrorComponent;

  const [rolesLoading, rolesError, requestedRoles] = useRequestedRoles(roles);
  const [permissionsLoading, permissionsError, requestedPermissions] = useRequestedPermissions(permissions);

  const error = rolesError || permissionsError;

  if (rolesLoading || permissionsLoading) {
    return <LoadingComponent />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else {
    return <>{cloneElement(children, { ...props, requestedRoles, requestedPermissions })}</>;
  }
};

export const WithRequestedAuthzByUser = ({
  children,
  ...props
}: WithRequestedAuthzByUserComponentProps): JSX.Element => {
  const roles = props.byUserRoles || [];
  const permissions = props.byUserPermissions || [];
  const userUuid = props.userUuid;
  const LoadingComponent = props.loadingComponent || DefaultLoadingComponent;
  const ErrorComponent = props.errorComponent || DefaultErrorComponent;

  const [rolesLoading, rolesError, requestedRoles] = useRequestedRolesByAnyUserLocation(userUuid, roles);
  const [permissionsLoading, permissionsError, requestedPermissions] = useRequestedPermissionsByAnyUserLocation(
    userUuid,
    permissions,
  );

  const error = rolesError || permissionsError;

  if (rolesLoading || permissionsLoading) {
    return <LoadingComponent />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else {
    return (
      <>
        {cloneElement(children, {
          ...props,
          requestedRolesByUser: requestedRoles,
          requestedPermissionsByUser: requestedPermissions,
        })}
      </>
    );
  }
};

export const WithRequestedAuthzByAccount = ({
  children,
  ...props
}: WithRequestedAuthzByAccountComponentProps): JSX.Element => {
  const roles = props.byAccountRoles || [];
  const permissions = props.byAccountPermissions || [];
  const accountUuid = props.accountUuid;
  const LoadingComponent = props.loadingComponent || DefaultLoadingComponent;
  const ErrorComponent = props.errorComponent || DefaultErrorComponent;

  const [rolesLoading, rolesError, requestedRoles] = useRequestedRolesByAnyAccountLocation(accountUuid, roles);
  const [permissionsLoading, permissionsError, requestedPermissions] = useRequestedPermissionsByAnyAccountLocation(
    accountUuid,
    permissions,
  );

  const error = rolesError || permissionsError;

  if (rolesLoading || permissionsLoading) {
    return <LoadingComponent />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else {
    return (
      <>
        {cloneElement(children, {
          ...props,
          requestedRolesByAccount: requestedRoles,
          requestedPermissionsByAccount: requestedPermissions,
        })}
      </>
    );
  }
};

export function withRequestedAuthz<P>(hocProps: WithRequestedAuthzHOCProps) {
  return function (Component: React.ComponentType<P>): React.ComponentType<P> {
    const WrappedComponent = function (props: P): JSX.Element {
      const roles = hocProps.roles || [];
      const permissions = hocProps.permissions || [];
      const ErrorComponent = hocProps.errorComponent || DefaultErrorComponent;
      const LoadingComponent = hocProps.loadingComponent || DefaultLoadingComponent;

      const [rolesLoading, rolesError, requestedRoles] = useRequestedRoles(roles);
      const [permissionsLoading, permissionsError, requestedPermissions] = useRequestedPermissions(permissions);

      const error = rolesError || permissionsError;

      if (rolesLoading || permissionsLoading) {
        return <LoadingComponent />;
      } else if (error) {
        return <ErrorComponent error={error} />;
      } else {
        return <Component {...props} requestedRoles={requestedRoles} requestedPermissions={requestedPermissions} />;
      }
    };
    WrappedComponent.displayName = 'withRequestedAuthz';
    return WrappedComponent;
  };
}

export function withRequestedAuthzByUser<P>(hocProps: WithRequestedAuthzByUserHOCProps) {
  return function (Component: React.ComponentType<P>): React.ComponentType<P> {
    const WrappedComponent = function (props: P): JSX.Element {
      const roles = hocProps.byUserRoles || [];
      const permissions = hocProps.byUserPermissions || [];
      const userUuid = hocProps.userUuid;
      const ErrorComponent = hocProps.errorComponent || DefaultErrorComponent;
      const LoadingComponent = hocProps.loadingComponent || DefaultLoadingComponent;

      const [rolesLoading, rolesError, requestedRoles] = useRequestedRolesByAnyUserLocation(userUuid, roles);
      const [permissionsLoading, permissionsError, requestedPermissions] = useRequestedPermissionsByAnyUserLocation(
        userUuid,
        permissions,
      );

      const error = rolesError || permissionsError;

      if (rolesLoading || permissionsLoading) {
        return <LoadingComponent />;
      } else if (error) {
        return <ErrorComponent error={error} />;
      } else {
        return (
          <Component
            {...props}
            requestedRolesByUser={requestedRoles}
            requestedPermissionsByUser={requestedPermissions}
          />
        );
      }
    };
    WrappedComponent.displayName = 'withRequestedAuthzByUser';
    return WrappedComponent;
  };
}

export function withRequestedAuthzByAccount<P>(hocProps: WithRequestedAuthzByAccountHOCProps) {
  return function (Component: React.ComponentType<P>): React.ComponentType<P> {
    const WrappedComponent = function (props: P): JSX.Element {
      const roles = hocProps.byAccountRoles || [];
      const permissions = hocProps.byAccountPermissions || [];
      const accountUuid = hocProps.accountUuid;
      const ErrorComponent = hocProps.errorComponent || DefaultErrorComponent;
      const LoadingComponent = hocProps.loadingComponent || DefaultLoadingComponent;

      const [rolesLoading, rolesError, requestedRoles] = useRequestedRolesByAnyAccountLocation(accountUuid, roles);
      const [permissionsLoading, permissionsError, requestedPermissions] = useRequestedPermissionsByAnyAccountLocation(
        accountUuid,
        permissions,
      );

      const error = rolesError || permissionsError;

      if (rolesLoading || permissionsLoading) {
        return <LoadingComponent />;
      } else if (error) {
        return <ErrorComponent error={error} />;
      } else {
        return (
          <Component
            {...props}
            requestedRolesByAccount={requestedRoles}
            requestedPermissionsByAccount={requestedPermissions}
          />
        );
      }
    };
    WrappedComponent.displayName = 'withRequestedAuthzByAccount';
    return WrappedComponent;
  };
}
